import {Col, Row} from "react-bootstrap";

function Video({site, edit, onChange, ...props}) {
    /**
     * IMPORTANT!!!!
     * Make sure to update the counter part in the laravel blade template
     */

    return (
        <section className="block block-details">
            <div className="container-fluid">
                <Row>
                    <Col md={12} lg={12} className="p-0 my-4">
                        <iframe src={site.video_url} className="d-block mx-auto site-video" title="Site Video"></iframe>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Video;
