import {useHistory, useLocation} from "react-router-dom";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import DatePicker from "react-datepicker";
import {Controller, useForm} from 'react-hook-form';
import * as moment from 'moment';
import LoadingPage from "@/components/LoadingPage";
import {useAuth} from "@/services/Auth";
import LoadingButton from "@/components/LoadingButton";
import {Link} from "react-router-dom";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select, { components } from "react-select";
import AccountType from "../../../../services/AccountType";
import PhoneInput from "react-phone-input-2";

function Parking({site, booking, onSubmit, errors: globalErrors, wait}) {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const route = useLocation();
    const history = useHistory();

    const [users, setUsers] = useState(false);
    const [vehicles, setVehicles] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(false);
    const [fleet, setFleet] = useState(null);
    useEffect(() => {
        const entity = JSON.parse(localStorage.getItem('entity'));
        if(fleet === null && auth.user.is_fleet_manager && entity.type === 'Fleet')
            auth.getRequest('/fleets/' + entity.type_id)
                .then(response => {
                    setFleet(response.data.fleet);
                })
    }, []);

    const schema = yup.object().shape({
        parking_booking: yup.boolean().nullable(),
        vehicle_id: yup.number().typeError(t('form_validation.is_required', {attribute: t('registration')})),
        driver_phone_number: yup.string().nullable(),
        from_datetime: yup
            .date()
            .transform((curr, orig) => orig === '' ? null : curr)
            .typeError(t('form_validation.is_required', {attribute: t('from')}))
            .when(['parking_booking', 'duration'], (parking_booking, duration, schema) => {
                return parking_booking && (!booking.instant_order_token || duration === 0) && schema
                    .required(t('form_validation.is_required', {attribute: t('from')}))
                    .test(
                        'is-future',
                        t('booking_cant_start_on_the_past'),
                        (value, context) => booking.instant_order_token || moment(value) >= moment().add(45, 'minutes')
                    ) || schema.nullable();
            }),

        duration: yup
            .number()
            .typeError(t('form_validation.is_required', {attribute: t('duration')}))
            .when(['parking_booking'], (parking, schema) => {
                return parking && schema
                        .required(t('form_validation.is_required', {attribute: t('duration')}))
                    || schema.nullable();
            }),

        to_datetime: yup
            .date()
            .transform((curr, orig) => orig === '' ? null : curr)
            .typeError(t('form_validation.is_required', {attribute: t('to')}))
            .when(['parking_booking', 'from_datetime', 'duration'], (parking, from_datetime, duration, schema) => {
                return parking && from_datetime && !isNaN(from_datetime) && duration === 0 && schema
                        .required(t('form_validation.is_required', {attribute: t('fields.to_datetime')}))
                        .min(moment(from_datetime).add(1, 'hour'), t('booking_duration_must_be_at_lest_one_hour'))
                    || schema.nullable();
            }),

        comments: yup.string().nullable().max(65000, t('form_validation.max_length', {
            attribute: t('comments'),
            length: 65000
        })),
    }).required();

    const {
        handleSubmit,
        control,
        register,
        formState: {errors},
        setError,
        watch,
        setValue
    } = useForm({
        defaultValues: (_ => {
            const booking = {..._};

            booking.parking_booking = site.parking_available ? (typeof booking.parking_booking != 'undefined' ? booking.parking_booking && '1' || '0' : null) : '0';
            booking.duration = typeof booking.duration != 'undefined' ? booking.duration + '' : null;
            booking.from_datetime = booking.from_datetime && moment(booking.from_datetime).format('YYYY-MM-DDTHH:mm');
            booking.to_datetime = booking.to_datetime && moment(booking.to_datetime).format('YYYY-MM-DDTHH:mm');

            if (route.state?.driver) {
                booking.user_id = route.state.driver;
            }

            if (route.state?.driver_phone_number) {
                booking.driver_phone_number = route.state.driver_phone_number;
            }

            if (route.state?.vehicle) {
                booking.vehicle_id = route.state.vehicle;
            }

            return booking;
        })(booking || {trailer: null, duration: null}),
        // mode: 'onBlur',
        // reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (!users && (auth.user.is_client_admin || (auth.user.is_fleet_manager && fleet?.driver_setup === 'Driver'))) {
            auth.getRequest('users?drivers=true', {take: 0})
                .then((response) => {
                    setUsers(response.data.users);
                })
        }
    }, [users, fleet]);


    useEffect(() => {
        register('parking_booking');
        register('trailer');
        register('from_datetime');
        register('to_datetime');
        register('duration');
    }, []);

    const from_datetime = watch('from_datetime');
    const to_datetime = watch('to_datetime');

    const parking = watch('parking_booking');
    const duration = watch('duration');
    const trailer = watch('trailer');
    const user_id = watch('user_id');

    useEffect(() => {
        if (users.length > 0 && user_id) {
            const user = users.find(u => u.id === user_id);
            if (!user) {return;}
            setSelectedDriver({value: user.id, label: user.name})
        }
    }, [users, user_id]);

    const driver_phone_number = watch('driver_phone_number');
    const vehicle_id = watch('vehicle_id');
    const vehicle = vehicle_id && vehicles && vehicles.find(v => v.id == vehicle_id);

    useEffect(() => {
        setVehicles(false);

        auth.getRequest(user_id ? `/users/${user_id}/vehicles` : 'vehicles', { take: 0, orderby: 'registration_number' })
            .then((response) => {
                setVehicles(response.data.vehicles);
            })
            .catch(response => console.error(response))
    }, [user_id]);

    useEffect(() => {
        const selected = vehicle_id && vehicles && vehicles.find(v => v.id == vehicle_id)
        if (selected) {
            setSelectedVehicle({label:t(selected.registration_number), value: selected.id })
        }
    }, [vehicles]);



    const availableIntervals = {
        hours: [],
        days: [],
    };

    if (site.parking_prices) {
        site.parking_prices.forEach(parkingPrice => {
            if (parkingPrice.per_period_type == 'Hour') {
                for (var i = parkingPrice.period_value; i <= parkingPrice.up_to_hours; i += parkingPrice.period_value) {
                    if (i % 24 == 0 && i / 24 <= 14) {
                        availableIntervals.days.includes(i / 24) || availableIntervals.days.push(i / 24);
                    } else if (i < 24) {
                        availableIntervals.hours.includes(i) || availableIntervals.hours.push(i);
                    }
                }
            }

            if (parkingPrice.per_period_type == 'Day') {
                const up_to_days = Math.floor(parkingPrice.up_to_hours / 24);

                for (var i = parkingPrice.period_value; i <= up_to_days; i += parkingPrice.period_value) {
                    if (i <= 30) {
                        availableIntervals.days.includes(i) || availableIntervals.days.push(i);
                    }
                }
            }
        })
    }

    availableIntervals.hours.sort((a, b) => a - b);
    availableIntervals.days.sort((a, b) => a - b);

    const AddDriverButton = (props) => {
        return (
            <components.MenuList {...props}>
                {
                    !auth.user?.is_driver && (
                        <div>
                            <Link className="btn btn-link w-100" to={{
                              pathname: '/users/create',
                              state: {
                              ...route.state,
                              redirect_to: '/bookings/create',
                              account_type: 'driver'
                            }}}>
                                {t('add_driver')}
                            </Link>
                        </div>
                    )
                }
                {props.children}
            </components.MenuList>
        )
    }

    const AddNewVehicleButton = (props) => {
        return (
            <components.MenuList {...props}>
                {
                    !auth.user?.is_driver && (
                        <div>
                            <Link className="btn btn-link w-100" to={{
                              pathname: '/vehicles/create',
                              state: {
                                ...route.state,
                                driver_phone_number: driver_phone_number,
                                redirect_to: '/bookings/create',
                              }
                            }}>
                              {t('new_vehicle')}
                            </Link>
                        </div>
                    )
                }

                {props.children}
            </components.MenuList>
        );
    };

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="booking booking-parking container-fluid py-4">
            <Helmet>
                <title>{t('create')} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            <Row>
                <Col xl={8} xxl={6}>

                    <h1 className="h2">{t('create_a_booking')} {t('steps', {current: 1, steps: 4})}</h1>

                    <Table bordered={true} className="my-4 small" style={{"fontSize": '1rem'}}>
                        <tbody>
                        <tr>
                            <th>{t('site')}</th>
                            <td>{site.name}</td>
                        </tr>

                        {site.journey && (
                            <>
                                <tr>
                                    <th>{t('distance')}</th>
                                    <td>{site.journey.distance.text}</td>
                                </tr>
                                <tr>
                                    <th>{t('journey_time')}</th>
                                    <td>{site.journey.duration.text}</td>
                                </tr>
                            </>
                        )}
                        </tbody>
                    </Table>

                    {
                        (
                            auth.user.is_client_admin ||
                            (auth.user.is_fleet_manager && fleet?.driver_setup === 'Driver')
                        )
                        && (
                        <Form.Group className="mb-2 pt-3">
                            <Form.Label>
                                {t('driver')}
                            </Form.Label>

                            {users && (
                                <Select
                                    className="react-select"
                                    isClearable={true}
                                    value={selectedDriver}
                                    onChange={selected => {
                                        if (!selected) {
                                            setSelectedDriver(false);
                                            setValue('user_id', null)
                                            return;
                                        }

                                        setSelectedDriver(selected);
                                        setValue('user_id', selected.value);
                                    }}
                                    placeholder={t('select_or_type_to_search')}
                                    components={{MenuList: AddDriverButton}}
                                    options={[
                                        ...users.map(user => ({
                                            label: user.name,
                                            value: user.id
                                        }))
                                    ]}
                                />
                            ) || (
                                <Form.Select className="react-select">
                                    <option value="">{t('loading')}</option>
                                </Form.Select>
                            )}
                            </Form.Group>
                    ) || ''}

                    {
                        (auth.user.is_fleet_manager && fleet?.driver_setup === 'DriverLess')
                        && (
                            <Form.Group className="mb-2 pt-3">
                                <Form.Label className="my-2">
                                    {t('driver_phone_number')}
                                </Form.Label>

                                <Controller
                                    control={control}
                                    {...register('driver_phone_number')}
                                    isInvalid={!!globalErrors?.driver_phone_number}
                                    render={({field: { ref, ...field }}) => (
                                        <PhoneInput
                                            country={'gb'}
                                            {...field}
                                            inputExtraProps={{
                                                ref
                                            }}
                                            inputStyle={{
                                                padding: '0.55rem 14px 0.55rem 60px',
                                                width: 'inherit'
                                            }}
                                            placeholder={t('enter_phone_number')}
                                        />
                                    )}
                                />


                                <Form.Control.Feedback type="invalid" className={!!globalErrors.driver_phone_number && 'd-block'}>
                                    {globalErrors?.driver_phone_number && globalErrors?.driver_phone_number[0]}
                                </Form.Control.Feedback>
                            </Form.Group>
                        ) || ''}

                    <Form.Group className={`mb-5 pt-3 ${(auth.user.is_fleet_manager || auth.user.is_client_admin) && (!driver_phone_number && !user_id) ? 'd-none' : '' }`}>
                        <Form.Label className="mb-4 h4">
                            {t('select_vehicle')}
                        </Form.Label>
                        {vehicles && (
                            <Select
                                className="react-select"
                                isClearable={true}
                                value={selectedVehicle}
                                onChange={selected => {
                                    if (!selected?.value) {
                                        setSelectedVehicle(false);
                                        setValue('vehicle_id', null)
                                        return;
                                    }

                                    setSelectedVehicle(selected);
                                    setValue('vehicle_id', selected.value);
                                }}
                                components={{ MenuList: AddNewVehicleButton }}
                                placeholder={t('select_or_type_to_search')}
                                options={[
                                    ...vehicles.map(vehicle => ({
                                        label: t(vehicle.registration_number),
                                        value: vehicle.id
                                    }))
                                ]}
                            />
                        ) || (
                            <Form.Select className="react-select">
                                <option value="">{t('loading')}</option>
                            </Form.Select>
                        )}

                        <Form.Control.Feedback type="invalid">
                            {errors?.vehicle_id?.message || (globalErrors?.vehicle_id && globalErrors?.vehicle_id[0])}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {vehicle && (
                        <>
                            {
                                vehicle.vehicle_issues && (
                                    <>
                                        <span className={'text-danger'}>
                                            { t('vehicle_existing_issue') }.&nbsp;
                                             <a href='#' onClick={(event) => {
                                                 event.preventDefault();
                                                 history.push(`/vehicles/${vehicle.id}/issues`,{vehicle});
                                             }}>
                                                  {t('view_details')}
                                             </a>
                                        </span>
                                    </>
                                )
                            }

                            {site.parking_available && (<>
                                <Form.Group className="mb-3">
                                    <Form.Label className="h4 mb-3">
                                        {t('parking_booking_question')}
                                    </Form.Label>

                                    <Row className="gx-2">
                                        <label className="col">
                                            <Form.Check.Input
                                                type="radio"
                                                {...register('parking_booking')}
                                                value={1}
                                                className="d-none"
                                            />


                                            <span className="btn btn-outline-primary w-100">{t('yes')}</span>
                                        </label>

                                        <label className="col">
                                            <Form.Check.Input
                                                type="radio"
                                                {...register('parking_booking')}
                                                value={0}
                                                className="d-none"
                                            />


                                            <span className="btn btn-outline-primary w-100">{t('no')}</span>
                                        </label>
                                    </Row>

                                    {globalErrors.parking_booking && (
                                        <div
                                            className="invalid-feedback d-block">{t(globalErrors.parking_booking[0])}</div>
                                    )}
                                </Form.Group>

                                {parking == 1 && (
                                    <>
                                        {
                                            (vehicle.vehicle_type === 'Truck' || vehicle.vehicle_type === 'TractorUnit') &&

                                            <Form.Group className="mb-3">
                                                <Form.Label className="h4 mb-3">
                                                    {t('parking_with_trailer')}
                                                </Form.Label>

                                                <Row className="gx-2">
                                                    <label className="col">
                                                        <Form.Check.Input
                                                            type="radio"
                                                            {...register('trailer')}
                                                            value={1}
                                                            className="d-none"
                                                        />


                                                        <span
                                                            className="btn btn-outline-primary w-100">{t('yes')}</span>
                                                    </label>

                                                    <label className="col">
                                                        <Form.Check.Input
                                                            type="radio"
                                                            {...register('trailer')}
                                                            value={0}
                                                            className="d-none"
                                                        />


                                                        <span className="btn btn-outline-primary w-100">{t('no')}</span>
                                                    </label>
                                                </Row>
                                            </Form.Group>
                                        }

                                        {(!(vehicle.vehicle_type === 'Truck' || vehicle.vehicle_type === 'TractorUnit') || trailer !== undefined) && (

                                            <Row>
                                                {(!booking.instant_order_token || duration === '0') &&
                                                    <Col xs={12}
                                                         className={`${booking.instant_order_token ? 'order-3' : ''}`}>
                                                        <Form.Label className="my-3 h4">
                                                            {t('booking_from')}
                                                        </Form.Label>

                                                        <Form.Control
                                                            placeholder={t('date')}
                                                            type="datetime-local"
                                                            min={booking.instant_order_token ? null : moment().add(45, "minutes").format('YYYY-MM-DDThh:mm')}
                                                            max={booking.instant_order_token ? moment().add(15, "minutes").format('YYYY-MM-DDThh:mm') : null}
                                                            {...register('from_datetime')}
                                                            isInvalid={!!errors.from_datetime}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.from_datetime && errors.from_datetime.message}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                }

                                                {(booking.instant_order_token || from_datetime) &&
                                                    <Col xs={12}>
                                                        <Form.Label className="h4 mb-3 mt-4">
                                                            {t('booking_duration')}
                                                        </Form.Label>

                                                        <Row className="gx-2">
                                                            {availableIntervals.hours.map((count, index) => (
                                                                <label key={index + 'hours'}
                                                                       className="col-6 col-lg-4 col-xl-3">
                                                                    <Form.Check.Input
                                                                        type="radio"
                                                                        {...register('duration')}
                                                                        value={count}
                                                                        className="d-none"
                                                                    />


                                                                    <span
                                                                        className="btn btn-outline-primary w-100 mb-2">{t('n_hours', {count})}</span>
                                                                </label>
                                                            ))}

                                                            {availableIntervals.days.map((count, index) => (
                                                                <label key={index + 'days'}
                                                                       className="col-6 col-lg-4 col-xl-3">
                                                                    <Form.Check.Input
                                                                        type="radio"
                                                                        {...register('duration')}
                                                                        value={count * 24}
                                                                        className="d-none"
                                                                    />


                                                                    <span
                                                                        className="btn btn-outline-primary w-100 mb-2">{t('n_days', {count})}</span>
                                                                </label>
                                                            ))}

                                                            <label key="custom" className="col-6 col-lg-4 col-xl-3">
                                                                <Form.Check.Input
                                                                    type="radio"
                                                                    {...register('duration')}
                                                                    value={0}
                                                                    className="d-none"
                                                                />

                                                                <span
                                                                    className="btn btn-outline-primary w-100 mb-2">{t('custom')}</span>
                                                            </label>
                                                        </Row>
                                                    </Col>
                                                }

                                                {from_datetime && duration === '0' &&
                                                    <Col xs={12} className="order-4">
                                                        <Form.Label className="my-3 h4">
                                                            {t('booking_to')}
                                                        </Form.Label>

                                                        <Form.Control
                                                            placeholder={t('date')}
                                                            type="datetime-local"
                                                            min={moment(from_datetime).add(1, "hours").format('YYYY-MM-DDThh:mm')}
                                                            {...register('to_datetime')}
                                                            isInvalid={!!errors.to_datetime}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.to_datetime && errors.to_datetime.message}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                }
                                            </Row>
                                        )}
                                    </>
                                )}
                            </>)}

                            <Form.Control.Feedback type="invalid" className={!!globalErrors.credit_limit && 'd-block'}>
                                {globalErrors?.credit_limit && globalErrors?.credit_limit[0] && t(globalErrors?.credit_limit[0])}
                            </Form.Control.Feedback>


                            {!!(parking === '0' || (booking.instant_order_token && duration > 0) || (from_datetime && duration > 0) || (from_datetime && to_datetime)) && (
                                <>

                                    {user_id && user_id != auth?.user?.id && (
                                        <Form.Group>
                                            <Form.Label className="h4 mb-3 mt-4">
                                                {t('comments')}
                                            </Form.Label>

                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                {...register('comments')}
                                                placeholder={t('comments_to_driver_placeholder')}
                                            />

                                            <Form.Control.Feedback type="invalid"
                                                                   className={!!errors.comments && 'd-block'}>
                                                {errors.comments?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    ) || ''}

                                    <Form.Control.Feedback type="invalid"
                                                           className={!!globalErrors.spaces && 'd-block'}>
                                        {globalErrors.spaces?.map((error, index) => (
                                            <>
                                                {t(error)}
                                            </>
                                        ))}
                                    </Form.Control.Feedback>

                                    <div className="mt-4">
                                        <Button type="submit" className="w-100" disabled={wait}>
                                            {wait ? t('please_wait') : t('continue')}
                                        </Button>
                                    </div>
                                </>
                            ) || ''}

                        </>
                    )}


                    <Form.Control.Feedback type="invalid" className={!!globalErrors.instant_order_token && 'd-block'}>
                        {globalErrors?.instant_order_token && globalErrors?.instant_order_token[0] && t(globalErrors?.instant_order_token[0])}
                    </Form.Control.Feedback>
                </Col>
            </Row>
        </Form>
    );
}

export default Parking;


