import {Helmet} from "react-helmet";
import {Card, Col, Row, Form, Button, Spinner, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../services";
import {useHistory} from "react-router-dom";
import Select from "react-select";
import {Field} from "formik";
import {useTranslation} from "react-i18next";
import * as moment from "moment-timezone";

export default function SearchVerify(props) {
  const auth = useAuth();
  const {t, i18n} = useTranslation();
  const history = useHistory();
  const [sites, setSites] = useState([]);
  const [driver, setDriver] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [booking, setBooking] = useState(null);
  const [bookingToken, setBookingToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const {handleSubmit, register, setError, formState: {errors}} = useForm({
    defaultValues: {
      vehicle_registration_number: '',
      driver_identifier: '',
    }
  });

  useEffect(() => {
    (async () => {
      const response = await auth.getRequest(`/site-operator-instant-booking/sites`).then(response => response.data);
      setSites(response);
    })()
  }, []);

  const onSubmit = async (values) => {
    setLoading(false);
    setVehicle(null);
    setDriver(null);
    setBooking(null);
    setBookingToken(null);

    if (
      (!values.vehicle_registration_number || values.vehicle_registration_number?.length === 0)
    ) {
      return;
    }

    setLoading(true);

    const inputParams = {
      vehicle_registration_number: values.vehicle_registration_number,
      include: ['fleet']
    };

    const params = new URLSearchParams(inputParams).toString();

    auth.getRequest(`/site-operator-instant-booking/vehicles?${params}`)
      .then(response => {
        const data = response.data;
        setVehicle(data.vehicle)
        setBooking(data.booking);
        setBookingToken(data.booking_token);
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          const serverErrors = error.response.data.errors;

          Object.entries(serverErrors).forEach(([fieldName, fieldErrors]) => {
            setError(fieldName, {
              type: "server",
              message: fieldErrors[0]
            });
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });

  }



  const confirmInstantBooking = (e) => {
    e.preventDefault();

    const baseData = {
      vehicle: vehicle,
      site: sites[0],
      canChange: sites.length !== 1,
      sites: sites
    }

    console.log(baseData);

    history.push('/instant-booking/confirmation', baseData);
  }

  function UpcomingBooking({booking}) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const {t} = useTranslation();
    const history = useHistory();

    if (booking) {
      const fromDate = moment.utc(booking.from_datetime).tz(timezone);
      return (
        <>
          <Card
            onClick={() => history.push(`/bookings/${booking?.id}/edit`, {
              from: props.location,
              token: bookingToken
            })}
            style={{cursor: "pointer"}}
            className="card bg-success text-light small mb-3 p-3 p-xl-3"
          >
            <Card.Body className="p-0 position-relative">
              <div className="d-flex flex-column justify-content-between">
                <h5 className="mb-1">{t('dashboard.driver.upcoming_bookings')}</h5>
                <h6 className="mb-0">{booking.site.name}</h6>

                { booking.user &&
                  <h6 className="mb-0">{booking.user.name}</h6>
                }
              </div>
              <p className="small mt-2">{booking.site.address}</p>
              {booking.from_datetime &&
                <p className="small mb-0">{`${fromDate.format(t('formats.short_datetime_reverse'))}`}</p>
              }
              {!booking.from_datetime &&
                <p className="small mb-0">Current Booking</p>
              }
            </Card.Body>
          </Card>
        </>
      );
    }

    return (
      <></>
    );
  }

  return (
    <Card className="p-4">
      <Helmet>
        <title>Search Verify for your booking</title>
      </Helmet>

      <Card.Subtitle>
        <Row>
          <Col cols="12" className="d-flex justify-content-between">
            <h4>Search and Verify</h4>
          </Col>
        </Row>
      </Card.Subtitle>

      <Row>
        <Col sm={4}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="my-4">
              <Col>
                <Form.Group className="mb-4">
                  <Form.Label>{t('vehicle_registration_number')}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('vehicle_registration_number')}
                    placeholder="Search for registration numuber"
                  />
                  <Form.Control.Feedback type="invalid" className={errors.vehicle_registration_number ? 'd-block' : ''}>
                    {errors.vehicle_registration_number && errors.vehicle_registration_number.message}
                  </Form.Control.Feedback>
                </Form.Group>


                <div className="d-grid gap-2">
                  {
                    !loading ?
                      <Button type="submit" variant="success">
                        {t('search')}
                      </Button>
                      :
                      <Button variant="success" disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="mx-2">{t('please_wait')}</span>
                      </Button>
                  }
                </div>

              </Col>
            </Row>
          </Form>
        </Col>
        <Col></Col>
        <Col sm={7}>
          {
            vehicle && (
              <>
                <div className="p-2">
                  <Row>
                    Vehicle: {vehicle.registration_number}
                  </Row>
                  <Row>
                    Type: {vehicle.vehicle_type}
                  </Row>
                  <Row>
                    Fleet: {vehicle.fleet.name} - {vehicle.fleet.client.name}
                  </Row>
                  {
                    driver && (
                      <Row>
                        Driver: {driver.name}
                      </Row>
                    )
                  }
                </div>

                  <Row>
                    {sites.length < 1 ? (
                      <OverlayTrigger
                        placement='top'
                        trigger={['hover', 'focus']}
                        show={true}
                        overlay={
                          <Tooltip id='tooltip-failed-launch'>
                            { t('instant_booking.no_sites') }
                          </Tooltip>
                        }
                      >
                        <button
                          className="btn btn-primary"
                          disabled={true}
                        >
                          Launch Instant Booking
                        </button>
                      </OverlayTrigger>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={confirmInstantBooking}
                      >
                        Launch Instant Booking
                      </button>
                    )}


                  </Row>
              </>
            )
          }

          {
            booking &&
            <>
              <div className="d-flex align-items-center text-center my-3">
                <hr className="flex-grow-1 me-2"/>
                <span className="mx-2">OR</span>
                <hr className="flex-grow-1 ms-2"/>
              </div>
              <UpcomingBooking booking={booking}/>
            </>
          }
        </Col>
      </Row>


    </Card>
  )
}
