import React, { useEffect, useState } from "react";
import Table, { useColumns } from "@/components/Tables/Reports";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Form, Pagination, Row } from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "@/services/Auth";
import * as csv from 'csv-stringify/lib/sync';
import FileSaver from 'file-saver';
import { Helmet } from "react-helmet";
import Filters from "@/components/Forms/ReportsFilter";

function Reports() {
  const history = useHistory();
  const route = useLocation();

  let auth = useAuth();

  const {t} = useTranslation();
  const columns = useColumns();
  const exportColumns = useColumns(undefined, true);

  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);

  const exportReports = () => {
    let headers = exportColumns.map(_ => _.text);

    let exportRequest = { ...query };

    exportRequest.skip = 0;
    exportRequest.take = 0;
    exportRequest.include = 'location';

    if(auth.roles.view_vehicles)
    {
      exportRequest.include += ',vehicle';
    }

    if(auth.roles.view_users)
    {
      exportRequest.include += ',user';
    }

    if(auth.roles.view_fleets)
    {
      exportRequest.include += ',fleet';
    }

    if(auth.roles.view_clients)
    {
      exportRequest.include += ',fleet.company';
    }

    setExporting(true);

    auth.getRequest('/reports', exportRequest)
      .then(response => {

        const records = response.data.data.map((record) => {
          return exportColumns.map(_ => {
            var value = record,
            paths = _.dataField.split('.'),
            name;

            while((name = paths.shift()) && value)
            {
              value = value[name];
            }

             return _.exportFormatter ? _.exportFormatter(value, record) : value;
          });
        });

        records.unshift(headers);

        const data = "\uFEFF" + csv.stringify(records, {});
        const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

        FileSaver.saveAs(blob, 'reports.csv', {});

        setExporting(false);
      })
      .catch(error => {
        console.error(error);

        setExporting(false);
      })
  }


  const [query, setQuery] = useState({...(route?.state?.query || {}), outstanding: true});

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,

        query,
      }
    )
  }, [query]);

  return (

    <Card className="mx-2 my-2 p-2">
      <Helmet>
        <title>{t('drawer_link_titles.compliance')} - {t('app')}</title>
      </Helmet>

      <Card.Subtitle>
        <Row>
          <Col cols="12" className="d-flex justify-content-between flex-md-row flex-column">
            <h4 className="text-primary">{t('drawer_link_titles.compliance')}</h4>
            <div className="d-flex flex-md-row flex-column">
              <Button variant="success" className="mx-1 my-1 my-md-0" onClick={() => exportReports()}>{t('export')} (.csv)</Button>
            </div>
          </Col>
        </Row>

        <Filters searching={loading || exporting} values={query} onSubmit={setQuery} />
      </Card.Subtitle>

      <Table query={query} showPagination={true} onData={_ => setLoading(!_)} />
    </Card>
  )
}

export default Reports;
