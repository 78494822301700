import React, { useEffect, useState, useRef }   from "react";
import { Link, useParams }              from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import * as moment                    from "moment";
import { useTranslation }               from "react-i18next";
import { useAuth }                      from "@/services/Auth";
import Paginate from "../Paginate";
import useMoment from "../../hooks/useMoment";

function useColumns(filter, isExport) {
  const auth = useAuth();
  const {t} = useTranslation();

  const {momentLocal} = useMoment()

  let columns = [
    {
      dataField: 'id',
      text     : t('id'),
      formatter: (cell, row) => row?.guid && <Link to={`/compliance/${row?.guid}`}>{String(cell).padStart(5, '0')}</Link> || cell,
      exportFormatter: cell => cell,
    },
    auth.roles.view_users && {
      dataField: 'user',
      text     : t('user'),
      formatter: user => user && <Link to={`/users/${user.id}`}>{user.name}</Link>,
      exportFormatter: user => user?.name,
    } || '',
    {
      dataField: 'type',
      text     : t('compliance.report_type'),
      formatter: cell => t(`compliance.${cell}.title`),
    },
    {
      dataField: 'location',
      text     : t('location'),
      formatter: cell => cell && ( cell.address_1 || '') + ' ' +
        ( cell.county || '') + ' ' +
        ( cell.city || '') + ' ' +
        ( cell.postcode || '') + ' ',
      exportFormatter: cell => cell && ( cell.address_1 || '') + ' ' +
        ( cell.county || '') + ' ' +
        ( cell.city || '') + ' ' +
        ( cell.postcode || '') + ' ',
    },
    {
      dataField: 'vehicle',
      text     : t('vehicle'),
      formatter: vehicle => vehicle && <Link to={`/vehicles/${vehicle.id}`}>{vehicle.registration_number}</Link>,
      exportFormatter: vehicle => vehicle?.registration_number,
    },
    {
      dataField: 'status',
      text     : t('status'),
      formatter: (status, report) => t(`compliance.report_status.${status}`) + (report.severity ? ' - ' + t(`compliance.severity.${report.severity}`) : ''),
      exportFormatter: status => status,
    },
    {
      dataField: 'occurance_at',
      text: t('compliance.datetime'),
      formatter: value => momentLocal(value).format(t('formats.datetime')),
    },
    auth.roles.view_fleets && {
      dataField: 'fleet',
      text     : t('fleet_name'),
      formatter: (cell, row) => cell?.id && <Link to={`/fleets/${cell?.id}`}>{cell?.name}</Link> || cell,
      exportFormatter:(fleet) => fleet?.name,
    } || '',
    auth.roles.view_clients && {
      name: 'client',
      dataField: 'fleet.client',
      text     : t('client'),
      formatter: (cell, row) => cell?.id && <Link to={`/clients/${cell?.id}`}>{cell?.name}</Link> || cell,
      exportFormatter:(cell) => cell?.name,
    } || '',
      auth.roles.view_clients && {
          name: 'account',
          dataField: 'fleet.client',
          text     : t('account_number'),
          formatter: (cell, row) => cell?.account_number,
          exportFormatter:(cell) => cell?.account_number,
      } || '',
  ].filter(_ => !!_);

  return filter && columns.filter(filter) || columns;
}

function ReportsTable({ data: recordsProp, paginate, url, query, ...props }) {
  let auth = useAuth();
  const {t} = useTranslation();

  const perPage = props.perPage || 20;
  const exclude = props.exclude || [];
  const showPagination = paginate == null ? true : paginate;

  const request = useRef(null);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotal] = useState(false);
  const [recordsDownloaded, setRecords] = useState(false);

  const getRecords = () => {
    request?.current?.abort && request.current.abort();

    const critera = { ... (query || {})};

    critera.take = perPage;
    critera.skip = page * perPage;
    critera.include = 'location';

    if(auth.roles.view_vehicles)
    {
      critera.include += ',vehicle';
    }

    if(auth.roles.view_users)
    {
      critera.include += ',user';
    }

    if(auth.roles.view_fleets)
    {
      critera.include += ',fleet';
    }

    if(auth.roles.view_clients)
    {
      critera.include += ',fleet.company';
    }

    request.current = auth.getRequest(url || '/reports', critera);

    setRecords(false);

    request.current
      .then(response => {
        setRecords(response.data.data);
        setTotal(response.data.length);
      })
  }

  const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name));

  const rowClasses = (row, rowIndex) => {

    return row.status != 'Complete' && (row.status == 'Resolved' && 'table-success' || row.severity == 'Severe' && 'table-danger' || row.severity == 'Fault' && 'table-warning' || 'table-info') || '';
  };

  const changePage = ({ selected }) => {
    setPage(selected)
  }

  const records = recordsProp || recordsDownloaded;

  useEffect(() => {
    if(!recordsProp)
    {
      getRecords();
    }

    return () => request?.current?.abort && request.current.abort();
  }, [page, query, url]);

  useEffect(() => {
    props.onData && props.onData(records);
  }, [records]);

  useEffect(() => {
    props.onTotal && props.onTotal(totalRecords);
  }, [totalRecords]);

  return (
    <>
      <BootstrapTable
        keyField="id"
        striped
        responsive
        hover
        columns={columns || []}
        data={records || []}
        noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
        rowClasses={rowClasses}
        {...props}
      />
      {
        showPagination && records && totalRecords &&
        <Paginate {...{ changePage, totalRecords, page, perPage }} />
        || ''
      }
    </>
  );
}

export default ReportsTable;

export {
  useColumns
};
