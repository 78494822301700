import {useLocation} from "react-router-dom";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from 'react-hook-form';
import LoadingPage from "@/components/LoadingPage";
import {useAuth} from "@/services/Auth";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {Helmet} from "react-helmet";
import useMoment from "@/hooks/useMoment";

function Addons({site, booking, onSubmit, setBookingErrors, vehicle, driver, cancelBooking}) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const {t} = useTranslation();
    let auth = useAuth();

    const {momentLocal, moment}  = useMoment()

    const route = useLocation();


    const schema = yup.object().shape({
        addons: yup.array()
            .typeError(t('form_validation.addons_required_without_parking'))
            .when([], (schema) => {
                return booking.parking_booking && schema.nullable() ||
                    schema
                        .required(t('form_validation.addons_required_without_parking'))
                        .min(1, t('form_validation.addons_required_without_parking'));
            }),
    }).required();

    const [availableServices, setServices] = useState(route.state && route.state.availableServices || false);

    const {
        handleSubmit,
        formState: {errors},
        register,
    } = useForm({
        defaultValues: {
            ...(booking || {})
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (!availableServices) {
            auth.postRequest('bookings/quote', booking)
                .then(response => {
                    setServices(response.data.available_services);
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        setBookingErrors(error.response.data.errors);
                    }
                })
        }

    }, [availableServices]);


    if (!availableServices) {
        return <LoadingPage/>;
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="booking booking-addons container-fluid">
            <Helmet>
                <title>{t('addons')} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            <Row>
                <Col xl={8} xxl={6}>

                    <h6 className="text-primary">{t('booking.instant_booking')}</h6>

                    <p className="extra-small mb-0">{site.name}</p>
                    <p className="extra-small mb-0">
                        {momentLocal(moment.now()).format(t('formats.short_datetime_reverse'))}
                    </p>
                    <p className="extra-small mb-0">{driver ? driver.name : t('no_driver')}</p>
                    <p className="border-2 border-bottom extra-small mb-0 pb-2">{vehicle.registration_number}</p>


                    <h6 className="text-primary mt-3">{t('addons')}</h6>

                    <input
                        type="checkbox"
                        value={0}
                        {...register('addons[]')}
                        className="d-none"
                    />

                    <Row className="gx-2">
                        {availableServices?.map && availableServices.map((service, index) => (
                            <label key={index + "addon"}
                                   className="col-6 col-lg-4 col-xl-3">
                                <Form.Check.Input
                                    type="checkbox"
                                    {...register('addons[]')}
                                    value={service.site_service_id}
                                    className="d-none"
                                />
                                <span
                                    className="btn btn-outline-primary w-100 mb-2 small-checkbox">{service.name}</span>
                            </label>
                        ))}
                    </Row>

                    <Form.Control.Feedback type="invalid" className={!!errors.addons && 'd-block'}>
                        {errors.addons?.message}
                    </Form.Control.Feedback>


                    <div className="mt-4">
                        <div className="mt-4">
                            <Button type="submit" variant="success"  className="w-100">
                                {t('continue')}
                            </Button>
                        </div>
                        <div className="mt-4">
                            <Button className="w-100" variant="danger" onClick={cancelBooking}>
                                {t('cancel')}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default Addons;
